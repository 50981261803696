import React, { FC } from 'react'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import { observer } from 'mobx-react'

interface IThirdIntakeStep {
    model: IVendorsLeadIntakeModel
}

const ThirdIntakeStep: FC<IThirdIntakeStep> = observer(({ model }) => {
  return (
        <div className='third_step w-100 p-3'>
            <div className='w-100'>
                <h2 className='mb-3'>Имя<span className='important'>*</span></h2>
                <input
                    value={model.orderInfo.client_name}
                    onChange={(e) => model.setClientName(e.target.value)}
                    type='text'
                    placeholder='Укажите ваше имя'
                    name="name_field"
                    id="name_field" />
            </div>
            <div className='w-100'>
                <h2 className='mb-3'>Телефон<span className='important'>*</span></h2>
                <input
                    value={model.orderInfo.client_phone}
                    onChange={(e) => model.setClientPhone(e.target.value)}
                    type='text'
                    placeholder='Укажите ваш телефон'
                    name="phone_field"
                    id="phone_field" />
            </div>
            <div className='w-100 favorite_connect'>
                <h2 className='mb-3'>Предпочтительный способ связи</h2>
                <div className='mb-3 d-flex flex-wrap-none align-items-center gap-3'>
                    <input type='radio' checked={model.orderInfo.favorite_connect === 'whatsapp'} onChange={() => model.setFavoriteConnect('whatsapp')} id='whatsapp_option' name='favorite_connect' value='whatsapp' />
                    <label className='ms-1' htmlFor='whatsapp_option'>WhatsApp</label>
                </div>
                <div className='mb-3 d-flex flex-wrap-none align-items-center gap-3'>
                    <input type='radio' checked={model.orderInfo.favorite_connect === 'phone'} onChange={() => model.setFavoriteConnect('phone')} id='call_option' name='favorite_connect' value='call' />
                    <label className='ms-1' htmlFor='call_option'>Звонок</label>
                </div>
            </div>
            <div className='actions mt-5 w-100 d-flex justify-content-center'>
                <button onClick={() => model.setCurrentStep(3)} type='button' className='main_submit'>Далее</button>
            </div>
        </div>
  )
})

export default ThirdIntakeStep

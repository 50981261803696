import { observer } from 'mobx-react'
import React, { FC } from 'react'
import PhoneInput from 'react-phone-input-2'
import { injectIntl } from 'react-intl'

interface IPhoneWithCode {
    countryCode: string
    country?: string
    countryCodeCallback: (countryCode: string) => void
    phone: string
    phoneCallback: (phone: string) => void
    clearError?: () => void
    phoneClassname?: string
    countryCodeClassname?: string
    id?: string
    readOnly?: boolean
    intl: any
}

const countryCodeMap: { [key: string]: string } = {
  '+1': 'us',
  '+7': '', // kz and ru both fit, so set nothing
  '+996': 'kg',
  '+998': 'uz',
  '+353': 'ie',
  '+380': 'ua',
  '+44': 'gb',
  '+61': 'au',
  '+971': 'ae'
}

const PhoneWithCode: FC<IPhoneWithCode> = observer((
  {
    countryCode,
    country,
    countryCodeCallback,
    phone,
    phoneCallback,
    clearError,
    phoneClassname,
    countryCodeClassname,
    id,
    readOnly = false,
    intl
  }) => {
  const currentCountry = country || countryCodeMap[countryCode]
  return (
        <div className='d-flex gap-1'>
            <PhoneInput
                country={currentCountry}
                onlyCountries={['us', 'kg', 'kz', 'ru', 'ie', 'gb', 'au', 'uz', 'ua', 'ae']}
                localization={{
                  us: intl.formatMessage({
                    id: 'countries.us',
                    defaultMessage: 'United States'
                  }),
                  kg: intl.formatMessage({
                    id: 'countries.kg',
                    defaultMessage: 'Kyrgyzstan'
                  }),
                  uz: intl.formatMessage({
                    id: 'countries.uz',
                    defaultMessage: 'Uzbekistan'
                  }),
                  kz: intl.formatMessage({
                    id: 'countries.kz',
                    defaultMessage: 'Kazakhstan'
                  }),
                  ru: intl.formatMessage({
                    id: 'countries.ru',
                    defaultMessage: 'Russia'
                  }),
                  ua: intl.formatMessage({
                    id: 'countries.ua',
                    defaultMessage: 'Ukraine'
                  }),
                  ie: intl.formatMessage({
                    id: 'countries.ie',
                    defaultMessage: 'Ireland'
                  }),
                  gb: intl.formatMessage({
                    id: 'countries.gb',
                    defaultMessage: 'United Kingdom'
                  }),
                  au: intl.formatMessage({
                    id: 'countries.au',
                    defaultMessage: 'Australia'
                  }),
                  ae: intl.formatMessage({
                    id: 'countries.ae',
                    defaultMessage: 'United Arab Emirates'
                  })
                }}
                enableSearch
                countryCodeEditable={false}
                disableDropdown={readOnly}
                dropdownClass={countryCodeClassname}
                value={countryCode}
                containerClass='only__dropdown_input'
                onKeyDown={event => event.preventDefault()}
                onChange={countryCode => {
                  if (countryCodeCallback) countryCodeCallback(countryCode)
                }}
            />
            <input type="hidden"
                   value={countryCode}
                   id="country_code"
                   name="country_code"/>
            <input type="tel"
                   id="tel"
                   onClick={clearError}
                   value={phone}
                   onChange={(e) => {
                     if (phoneCallback) phoneCallback(e.target.value)
                   }}
                   className={phoneClassname}
                   placeholder='9999999999' name="phone"
                   readOnly={readOnly}/>
        </div>
  )
})

export default injectIntl(PhoneWithCode)

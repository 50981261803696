import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import VendorServiceItem from '../VendorServiceItem'
import LargePlus from '../../../../../assets/images/vendors/services_landing_stock/icons/large_plus.svg'
import { search_with_params_vendors_path } from '../../../../routes.js.erb'

interface IPopularServicesListProps {
    locale: string
    length: number
    popularVendors: any
    location: any
    category: any
    locationSlug: string
    categorySlug: string
    vendorsCount: any
}

const PopularServicesList: FC<IPopularServicesListProps> = ({ locale, length, popularVendors, location, category, locationSlug, categorySlug, vendorsCount }) => {
  return (
        <div className='services_list mt-5 d-flex gap-4 flex-wrap justify-content-around align-items-center'>
            {popularVendors.slice(0, length).map((vendor: any) =>
                <VendorServiceItem locale={locale} location={location} vendor={vendor} key={`supplier_popular_${vendor.id}`}/>
            )}
            <a href={search_with_params_vendors_path(locale, locationSlug, categorySlug)}>
                <div className="see_more_block d-flex flex-column align-items-center gap-4">
                <FormattedMessage
                    id='vendors.see_more'
                    tagName='h3'
                    values={{
                      count: vendorsCount,
                      category: <FormattedMessage id={`vendors.categories.${category.translation_key}.other`} />
                    }}
                />
                    <img src={LargePlus} alt="See more"/>
                </div>
            </a>
        </div>
  )
}

export default PopularServicesList

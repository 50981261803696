import React, { FC } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Vendor } from './types/Vendor.type'
import { FormattedMessage } from 'react-intl'
import { vendor_path } from '../../../routes.js.erb'

interface IVendorServiceItem {
    locale: string
    isPromo?: boolean
    vendor: Vendor;
    location: any
}

const VendorServiceItem: FC<IVendorServiceItem> = ({ locale, isPromo, vendor, location }) => {
  let result = <div></div>
  if (vendor) {
    result = (
            <div className={isPromo ? 'search_service_card promo' : 'search_service_card'}>
                <a target='_blank' rel='noreferrer' href={vendor_path(locale, vendor.slug)}>
                    <div className="search_service_img">
                        <img src={vendor.thumbnail_url} alt="ServiceImg"/>
                    </div>
                    <div className="search_service_info">
                        <div className="search_service_header d-flex">
                            <img width='40px' height='40px' className='service_logo' src={vendor.image_url}/>
                            <div className='ms-2'>
                                <h6>{vendor.name}</h6>
                            </div>
                            <span className='advertisement align-self-start ms-auto'>
                    <FormattedMessage id={`locations.${location.translation_key}`}
                                      defaultMessage={location.name_default}/>
                    </span>
                        </div>
                        <div
                            className="search_service_description mt-3">{ReactHtmlParser(vendor.rich_description.body)}</div>
                    </div>
                    {isPromo &&
                        <div className="promo_info pt-1 pb-1">
                            Промо
                        </div>
                    }
                </a>
            </div>
    )
  }
  return result
}

export default VendorServiceItem

import React from 'react'
import { observer } from 'mobx-react'

const PaginationOption = observer(({ option, model }) => {
  return (
    option.checked.length > 0
      ? <button className='events__pages_option checked' id={option.id}>{option.content}</button>
      : <div onClick={() => model.setCurrentPage(option.id)} className='events__pages_option' id={option.id}>{option.content}</div>
  )
})

export default PaginationOption

import { observer } from 'mobx-react'
import React, { FC } from 'react'
import FirstIntakeStep from './FirstIntakeStep'
import SecondIntakeStep from './SecondIntakeStep'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import ThirdIntakeStep from './ThirdIntakeStep'

interface IIntakeSteps {
    model: IVendorsLeadIntakeModel
}

const IntakeSteps: FC<IIntakeSteps> = observer(({ model }) => {
  return (
        <div>
            {model.currentStep === 0 && <FirstIntakeStep model={model} />}
            {model.currentStep === 1 && <SecondIntakeStep model={model} />}
            {model.currentStep === 2 && <ThirdIntakeStep model={model} />}
        </div>
  )
})

export default IntakeSteps

import { observable, action, makeObservable } from 'mobx'

type IntakeOption = {
    icon?: string
    title: string
    add_info?: string
}

type FavoriteConnect = 'whatsapp' | 'phone'

interface IOrderInfo {
  client_name: string
  client_phone: string
  wishes: string
  deadlines: string
  additional: string
  favorite_connect: FavoriteConnect
}

export interface IVendorsLeadIntakeModel {
  currentStep: number
  setCurrentStep: (newStep: number) => void
  intakeOptions: IntakeOption[]
  selectedIntakeOption: IntakeOption | 'another'
  setIntakeOption: (id: number | 'another') => void
  orderInfo: IOrderInfo
  setClientName: (name: string) => void
  setClientPhone: (phone: string) => void
  setOrderWishes: (wish: string) => void
  setOrderDeadlines: (deadlines: string) => void
  setOrderAdditional: (additional: string) => void
  setFavoriteConnect: (connectType: FavoriteConnect) => void
}

class VendorsLeadIntakeModel implements IVendorsLeadIntakeModel {
  currentStep: number
  intakeOptions: IntakeOption[]
  selectedIntakeOption: IntakeOption | 'another'
  orderInfo: IOrderInfo

  constructor () {
    this.currentStep = 0
    this.intakeOptions = [
      { icon: '📄', title: 'Печать материалов', add_info: '(документы, баннеры, визитки, книги, постеры и другое)' },
      { icon: '🌟', title: 'Брендирование продукции', add_info: '(ручки, кружки, блокноты, одежда с логотипом)' },
      { icon: '🚀', title: 'Специальные проекты', add_info: '(нестандартная печать, твердые обложки и другое)' }
    ]

    this.orderInfo = {
      client_name: '',
      client_phone: '',
      wishes: '',
      deadlines: '',
      additional: '',
      favorite_connect: 'whatsapp'
    }

    this.selectedIntakeOption = this.intakeOptions[0]

    makeObservable(this, {
      currentStep: observable,
      intakeOptions: observable,
      selectedIntakeOption: observable,
      orderInfo: observable,
      setCurrentStep: action,
      setIntakeOption: action,
      setClientName: action,
      setClientPhone: action,
      setOrderWishes: action,
      setOrderDeadlines: action,
      setOrderAdditional: action
    })
  }

  setCurrentStep (newStep: number) {
    this.currentStep = newStep
  }

  setIntakeOption (id: number | 'another') {
    if (id === 'another') {
      this.selectedIntakeOption = id
    } else {
      this.selectedIntakeOption = this.intakeOptions[id]
    }
  }

  setClientName (name: string) {
    this.orderInfo.client_name = name
  }

  setClientPhone (phone: string) {
    this.orderInfo.client_phone = phone
  }

  setOrderWishes (wish: string) {
    this.orderInfo.wishes = wish
  }

  setOrderDeadlines (deadlines: string) {
    this.orderInfo.deadlines = deadlines
  }

  setOrderAdditional (additional: string) {
    this.orderInfo.additional = additional
  }

  setFavoriteConnect (connectType: FavoriteConnect) {
    this.orderInfo.favorite_connect = connectType
  }
}

export default VendorsLeadIntakeModel

import React, { FC } from 'react'
import { new_user_password_reset_path, auth_at_provider_path, login_path } from '../../routes.js.erb'
import { FormattedMessage, injectIntl } from 'react-intl'
import { observer } from 'mobx-react'
import PhoneWithCode from '../editor/ReactQuill/PhoneWithCode'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface ILoginForm {
    intl: any,
    model: any
}

const LoginForm: FC<ILoginForm> = observer(({ intl, model }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!executeRecaptcha) {
      console.error('Execute recaptcha function not yet available')

      return
    }

    const token = await executeRecaptcha('login')

    if (!token) {
      alert('reCAPTCHA verification failed. Please try again.')
      return
    }

    const recaptchaInput = document.createElement('input')
    recaptchaInput.type = 'hidden'
    recaptchaInput.name = 'g-recaptcha-response'
    recaptchaInput.value = token

    event.target.appendChild(recaptchaInput)

    model.setIsDisabled(true)
    event.target.submit()
  }

  return (
        <React.Fragment>
            <form action={login_path(LOCALE)} method='post' onSubmit={handleSubmit}>
                {/* model.currentStep === 'otp' && <OtpScreen model={model}/> */}
                {model.currentStep === 'phone' &&
                    <React.Fragment>
                        <label htmlFor="phone">
                            <FormattedMessage id='spa.simple_event_phone_label'
                                              defaultMessage='Enter your phone number'/>
                        </label>
                        <div className='w-100 d-flex align-items-center filed field login input gap-2'>
                          <PhoneWithCode
                            countryCode={model.countryCode}
                            countryCodeCallback={model.setCountryCode.bind(model)}
                            phone={model.phoneInputValue}
                            phoneCallback={model.setPhoneInputValue.bind(model)}
                          />
                        </div>
                    </React.Fragment>
                }

                {model.currentStep === 'email' && <label htmlFor="email">
                    <React.Fragment>
                        <FormattedMessage id='spa.simple_event_email_label'
                                          defaultMessage='Enter your email'/>
                        <input type='text'
                               id='email'
                               name='email'
                               className='field login'
                               onClick={() => model.setIsInputEmpty(false)}
                               placeholder={
                                   intl.formatMessage({
                                     id: 'user_session.email_placeholder',
                                     defaultMessage: 'Email'
                                   })}
                               value={model.emailInputValue}
                               onChange={(e) => model.setEmailInputValue(e.target.value)}
                               autoComplete='off'
                               readOnly={false}
                        />
                    </React.Fragment>
                </label>}
                {model.shouldShowOtpInput &&
                    <React.Fragment>
                            <div className='w-100 d-flex align-items-center filed field login input gap-2'>
                              <PhoneWithCode
                                countryCode={model.countryCode}
                                phone={model.phoneInputValue}
                                readOnly={true}
                              />
                            </div>
                            <div className='field login d-flex p-3 mt-3'>
                                <br/>
                                <input type='password' id='phone_otp' name='phone_otp' placeholder={
                                   intl.formatMessage({
                                     id: 'user_session.otp',
                                     defaultMessage: 'One time code'
                                   })}
                                   pattern='^\d{6}$'
                                   title= {
                                    intl.formatMessage({
                                      id: 'user_session.six_digits_otp',
                                      defaultMessage: 'Please enter a 6-digit code'
                                    })} required/>
                            </div>
                    </React.Fragment>
                }

                {model.shouldShowPasswordInput &&
                    <React.Fragment>
                        <input type='text'
                               id='email'
                               name='email'
                               className='email_form field login'
                               placeholder={
                                   intl.formatMessage({
                                     id: 'user_session.email_placeholder',
                                     defaultMessage: 'Email'
                                   })}
                               autoComplete='off'
                               value={model.emailInputValue}
                               readOnly={true}
                        />
                        <div className='field login d-flex p-3 mt-2'>
                            <br/>
                            <input type='password' id='password' name='password' placeholder={
                                intl.formatMessage({
                                  id: 'user_session.password_placeholder',
                                  defaultMessage: 'Password'
                                })}/>
                        </div>
                        <div className='mt-2 forgot_url login'>
                            <a href={new_user_password_reset_path(LOCALE)}>
                                <FormattedMessage id='user_session.signin.form_under'
                                                  defaultMessage='Forgot Username/Password?'/>
                            </a>
                        </div>
                    </React.Fragment>
                }

                {model.shouldShowAltDelimetr &&
                    <div className='mt-4 forgot_url login'>
                        <b>
                            <FormattedMessage id='spa.simple_event_social_option_login'
                                              defaultMessage='or register via'/>
                        </b>
                    </div>
                }

                {model.currentStep === 'phone' &&
                    <button type='button' onClick={(event) => {
                      model.setCurrentStep('email')
                      event?.preventDefault()
                    }}
                            className={`${model.isDisabled && 'disabled'} mt-3 d-flex gap-3 justify-content-start align-items-center sign_in_social email login`}>
                        <div className='logo'></div>
                        <div className='d-flex align-items-end w-100'>
                            <u>
                                <FormattedMessage id='user_session.signin.email' defaultMessage='Sign in with email'/>
                            </u>
                        </div>
                    </button>
                }

                {model.shouldShowPhoneSignIn &&
                    <button type='button' onClick={(event) => {
                      model.setCurrentStep('phone')
                      event?.preventDefault()
                    }}
                            className={`${model.isDisabled && 'disabled'} mt-3 d-flex gap-3 justify-content-start align-items-center sign_in_social phone login`}>
                        <div className='logo'></div>
                        <div className='d-flex align-items-end w-100'>
                            <u>
                                <FormattedMessage id='user_session.signin.phone' defaultMessage='Sign in with phone'/>
                            </u>
                        </div>
                    </button>
                }

                {model.shouldShowGoogleSignIn &&
                    <a href={model.isDisabled ? '' : auth_at_provider_path('google')}
                       className={`${model.isDisabled && 'disabled'} mt-3 d-flex gap-3 justify-content-start align-items-end sign_in_social google login`}>
                        <div className='logo'></div>
                        <div className='d-flex align-items-end w-100'>
                            <u>
                                <FormattedMessage id='user_session.signin.google' defaultMessage='Sign in with Google'/>
                            </u>
                        </div>
                    </a>
                }

                {model.shouldShowFBSignIn &&
                    <a href={model.isDisabled ? '' : auth_at_provider_path('facebook')}
                       className={`${model.isDisabled && 'disabled'} mt-3 d-flex gap-3 justify-content-start align-items-end sign_in_social facebook login`}>
                        <div className='logo'></div>
                        <div className='d-flex align-items-end w-100'>
                            <u>
                                <FormattedMessage id='user_session.signin.facebook'
                                                  defaultMessage='Sign in with Facebook'/>
                            </u>
                        </div>
                    </a>
                }

                {model.shouldShowNextButton &&
                    <div className='actions d-flex login mt-4 mb-1'>
                        <input type='submit' onClick={(event) => {
                          if (model.currentStep === 'phone' && model.phoneInputValue.length === 0) {
                            model.setIsInputEmpty(true)
                            event?.preventDefault()
                          }
                          if (model.currentStep === 'email' && model.emailInputValue.length === 0) {
                            model.setIsInputEmpty(true)
                            event?.preventDefault()
                          }
                        }}
                               disabled={model.isDisabled} value={
                            intl.formatMessage({
                              id: 'user_session.signin.submit_button',
                              defaultMessage: 'Next'
                            })}
                               className='main_submit'/>
                    </div>
                }
            </form>
        </React.Fragment>
  )
})

export default injectIntl(LoginForm)

import React, { FC } from 'react'
import CopyIcon from '../../../assets/images/leads/icons/CopyIcon.svg'

const GuestsLink: FC = () => {
  const tempInputValue = 'https://www.planning.how/invite/34134' // // Change for dynamic value
  return (
    <div className='guests_link d-flex flex-column gap-1 mt-3'>
        <label htmlFor="mode_link">Скопируйте ссылку ниже и отправляйте ее вашим гостям</label>
        <div className='position-relative'>
            <input className='w-100' disabled type="text" name="mode_link" id="mode_link" value={tempInputValue} />
            <button className='copy_button position-absolute p-2'>
                <img src={CopyIcon} alt="" />
            </button>
        </div>
        {/* <sub>Кликов по ссылке: <strong>{tempLinkClicks}</strong></sub> */}
    </div>
  )
}

export default GuestsLink

import React, { FC } from 'react'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import { observer } from 'mobx-react'

interface ISecondIntakeStep {
    model: IVendorsLeadIntakeModel
}

const SecondIntakeStep: FC<ISecondIntakeStep> = observer(({ model }) => {
  return (
        <div className='second_step w-100 p-3'>
            <div className='w-100'>
                <h2 className='mb-3'>Что именно вы хотите напечатать?<span className='important'>*</span></h2>
                <textarea
                    value={model.orderInfo.wishes}
                    onChange={(e) => model.setOrderWishes(e.target.value)}
                    placeholder='Мне нужно распечатать файлы формата А4, 46 листов, ЧБ...'
                    name="wishes_field"
                    id="wishes_field" />
            </div>
            <div className='w-100'>
                <h2 className='mb-3'>Когда вам нужно выполнить заказ?</h2>
                <input
                    value={model.orderInfo.deadlines}
                    onChange={(e) => model.setOrderDeadlines(e.target.value)}
                    type='text'
                    placeholder='Укажите конкретную дату или срок выполнения'
                    name="deadlines_field"
                    id="deadlines_field" />
            </div>
            <div className='w-100'>
                <h2 className='mb-3'>Дополнительные пожелания или комментарии</h2>
                <input
                    value={model.orderInfo.additional}
                    onChange={(e) => model.setOrderAdditional(e.target.value)}
                    type='text'
                    placeholder='Нужна доставка на этот адрес: Улица 123, подъезд 2'
                    name="additional_field"
                    id="additional_field" />
            </div>
            <div className='actions mt-5 w-100 d-flex justify-content-center'>
                <button onClick={() => model.setCurrentStep(2)} type='button' className='black_button'>Далее</button>
            </div>
        </div>
  )
})

export default SecondIntakeStep

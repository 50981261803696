import React, { FC } from 'react'
// import SupplierItem from './SupplierItem'
import VendorServiceItem from '../search/VendorServiceItem'
import { FormattedMessage } from 'react-intl'

interface ISuppliersList {
    model: any
}

const SuppliersList: FC<ISuppliersList> = ({ model }) => {
  return (
        <div className='block popular_suppliers'>
            <div className='block_content d-flex justify-content-between gap-5 mb-2 mb-md-4'>
                <FormattedMessage tagName='h3' id='vendors.popular_distributors'/>
            </div>
            <div className='suppliers d-flex gap-3'>
                {model.popularVendors.map((supplier: any, index: number) =>
                    <VendorServiceItem locale={model.locale} key={index} vendor={supplier} location={model.location}/>
                )}
            </div>
        </div>
  )
}

export default SuppliersList

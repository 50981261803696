import React, { FC } from 'react'
import GroupChat from '../../../assets/images/guests/GroupChat.png'
import Union from '../../../assets/images/guests/Union.png'
import { observer } from 'mobx-react'

const ModeSwitch: FC = observer(({ model }: any) => {
  return (
    <div className="mode_main mt-5 text-center">
        <h2>Выберите способ отправки:</h2>
        <div className="row mt-4">
            <div className={`col mode_block ${model.guestInviteOption === 'link' ? 'not_selected' : ''}`}>
                <div className="d-flex p-3 gap-5">
                    <img src={GroupChat} alt="Send" />
                    <div className="mode_info">
                        <h5>Отправить всем в 1 клик!</h5>
                        <p>Вам нужно будет создать список гостей с их контактами и далее вы сможете легко отправить пригласительные по Email, СМС или WhatsApp</p>
                    </div>
                </div>
                <div className="actions">
                    <button onClick={() => model.setGuestInviteOption('manual')} className="main_submit w-100">Выбрать</button>
                </div>
            </div>
            <div className='w-100 content_line'></div>
            <div className={`col mode_block ${model.guestInviteOption === 'manual' ? 'not_selected' : ''}`}>
                <div className="d-flex p-3 gap-5">
                    <img src={Union} alt="Send" />
                    <div className="mode_info">
                        <h5>Ссылка на приглашение!</h5>
                        <p>В 1 клик создайте ссылку на ваше приглашение и отправляйте как угодно и размещайте ее где угодно, даже в ваших соц. сетях!</p>
                    </div>
                </div>
                <div className="actions">
                    <button onClick={() => model.setGuestInviteOption('link')} className="main_submit w-100">Выбрать</button>
                </div>
            </div>
        </div>
    </div>
  )
})

export default ModeSwitch

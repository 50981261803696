import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import GuestsInfo from './GuestsInfo'
import GuestsItem from './GuestsItem'
import GuestsItemMock from './GuestsItemMock'
import GuestsLink from './GuestsLink'
import { FormattedMessage } from 'react-intl'
import CircularLoading from '../UI/CircularLoading/CircularLoading'

const GuestsList = observer(({ model }) => {
  useEffect(() => model.updateInfo(), [])

  const guests = []
  for (const [id] of Object.entries(model.allGuests)) {
    guests.push(<GuestsItem key={id} currentGuestId={id} model={model} />)
  }

  return (
        <React.Fragment>
            {model.isAwaiting &&
              <div className='guests__loader d-flex align-items-center justify-content-center'>
                <CircularLoading />
              </div>
            }
            {!model.isGuestsListEmpty &&
              <div className="guests__text">
                  <h4 className="mt-0"><FormattedMessage id='guests.header' defaultMessage='Guests list'/></h4>
              </div>
            }
            {!model.isGuestsListEmpty &&
              <GuestsInfo isMock={false} />
            }
            {guests}

            {model.guestInviteOption === 'manual' &&
              <div>
                <div className='mock_guest_title mb-1 mt-3'>
                  <FormattedMessage tagName='h2' id='guests.new_guest' defaultMessage='New guest'/>
                </div>
                <GuestsInfo isMock/>
                <GuestsItemMock model={model} />
              </div>}
            {model.guestInviteOption === 'link' && <GuestsLink model={model} />}
        </React.Fragment>
  )
})

export default GuestsList
